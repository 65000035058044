import React from 'react'
import {Complaint} from "../complaint";
import style from "./style.css";
import styles from "../complaint/style.css";
import _ from 'lodash'
import {Avatar} from "../../avatar";
import classnames from "classnames";
import Loader from "../../../../../components/local-loader";


export const Complaints = ({complaints}) => {
    if (complaints === null) {
        return <Loader text='Ещё чуть-чуть...'/>;
    }

    return (
        <div className={style.content}>
            <ul className={style.contentBlock}>
                {!_.isEmpty(complaints) ?
                    complaints.map((complaint) => (
                        <Complaint
                            lastName={complaint['creator']['first_name']}
                            firstName={complaint['creator']['last_name']}
                            description={complaint['description']}
                            creationDate={complaint['creation_date']}
                            photo={{'data': complaint['creator']['avatar']}}
                        />
                    ))
                    : <li
                        className={classnames(styles.container, styles.containerRight)}
                    >
                        <Avatar photo={null} size="m"/>
                        <div className={styles.content}>
                            <b className={styles.title}>{"Имя Фамилия"}</b>
                            Здесь будет сообщение
                            <span className={styles.time}>дата и время</span>
                        </div>
                    </li>
                }
            </ul>
        </div>
    )
}
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field, reduxForm, getFormValues, isValid, getFormSyncErrors, FieldArray } from 'redux-form'

import { getToken } from '../../../../__data__/utils'
import { TextInput, RadioGroupMenu, FormConstructor, PhoneInput } from '../../../../components/form'
import {SEX_ITEMS} from '../../../../__data__/constants'

import {
    nameValidate,
    surnameValidate,
    phoneValidate,
    required
} from '../../../../components/form/validation'
import {
    MODERATOR_STORAGE_NAME,
    FORM_NAMES,
    URL_LIST, RESIDENT_FORM_FIELDS_ERRORS,
} from '../../../../__data__/constants'
import formStyle from "../../../../theme/form.css"
import {renderUnits} from "../../utils/renderUnits";
import {submitCreatedResident} from '../../utils/submit'

function Component({initialize, formName}) {
    const token = getToken(MODERATOR_STORAGE_NAME);

    useEffect(() => {
        initialize({
            name: '',
            surname: '',
            units: ['']
        });
        document.body.style.overflow = "unset"
    }, [token])

    return (
        <FormConstructor
            goBackLinkText='Вернуться к резидентам'
            goBackLinkUrl={URL_LIST.residents}
            columnCount='5'
            buttonText='Отправить приглашение'
            formName={formName}
            contentTop={true}
            fieldErrors={RESIDENT_FORM_FIELDS_ERRORS}
        >
            <div className={formStyle.groupSection}>
                <Field
                    name="name"
                    component={TextInput}
                    type="text"
                    label="Имя"
                    validate={[nameValidate]}
                />
                <Field
                    name="surname"
                    component={TextInput}
                    type="text"
                    label="Фамилия"
                    validate={[surnameValidate]}
                />
                <RadioGroupMenu
                    name="gender"
                    items={SEX_ITEMS}
                    validate={[required]}
                />
                <Field
                    size="auto"
                    name="phoneNumber"
                    label="Телефон"
                    description="На этот номер будет отправлено приглашение"
                    component={PhoneInput}
                    type="text"
                    validate={[phoneValidate]}
                />
            </div>
            <div className={formStyle.groupSection}>
                <FieldArray name="units" component={renderUnits} formName={formName} />
            </div>
        </FormConstructor>
    )
}

const mapStateToProps = createStructuredSelector({
    formValues: getFormValues(FORM_NAMES.createResident),
    isFormValid: isValid(FORM_NAMES.createResident),
    fieldErrors: getFormSyncErrors(FORM_NAMES.createResident),
})

const withConnect = connect(
    mapStateToProps,
)

Component = reduxForm({
    form: FORM_NAMES.createResident,
    onSubmit: submitCreatedResident
}) (Component)

export const CreateResidentForm = compose(withConnect)(Component)

import React, {useState} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {createStructuredSelector} from 'reselect'
import {Link} from 'react-router-dom'
import {makeResidentsObject} from '../../__data__/selectors/residents'
import {URL_LIST} from '../../__data__/constants'

import style from './style.css'
import {default as Card} from './components/card'
import Filter from './components/filter'
import {default as Units} from './components/units'
import {checkWritePermissionForSection} from "../../__data__/actions/permissions";

function Component(props) {
    const {residentsObject} = props

    const [selected, setSelected] = useState(null)
    const isAvailableByPerms = checkWritePermissionForSection('Резиденты')
    const buttonText = '+ Пригласить новых'

    const handleResidentClick = (event, objectId) => {
        if (selected !== objectId) {
            setSelected(objectId)
        } else {
            setSelected(null)
        }
    }

    // const activeContact = _.find(residentsObject.list, object => _.find(object.units.list, i => i.object.id === selected))
    let activeContact = null
    for (let residentObject of residentsObject) {
        for (let unit of residentObject.units) {
            if (unit.object.id === selected) {
                activeContact = unit
            }
        }
    }

    return (
        <div className={style.container}>
            <div className={style.headerWrapper}>
                <div className={style.header}>
                    <div className={style.stub}/>
                    {!isAvailableByPerms ? (
                        <Link to={undefined} className={style.disabledLink}>{buttonText}</Link>
                    ) : (
                        <Link to={URL_LIST.createResidentForm} className={style.addContactLink}>{buttonText}</Link>
                    )}
                </div>
            </div>
            <div className={residentsObject.length < 1 ? style.residentsInit : style.residents}>
                <div className={style.filter}>
                    <Filter/>
                </div>

                <div className={style.units}>
                    <Units
                        residentsObject={residentsObject}
                        handleClick={handleResidentClick}
                        selected={selected}
                    />
                </div>

                {activeContact && <Card {...activeContact} handleClick={setSelected}/>}

            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    residentsObject: makeResidentsObject(),
})

const mapDispatchToProps = {}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)

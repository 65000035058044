import React, {PureComponent} from 'react'
import {Tinode} from 'tinode-sdk';
import {MESSAGES_PAGE} from '../../../constants/chat';
import {formatDate} from '../../../__data__/utils';
import {ContactInfo} from '../components/contact/contact-info';
import {Submit} from '../components/submit';
import {Messages} from '../components/messages';
import {Header} from '../components/header'
import {USERS_STATUS} from '../../../constants/chat'

import styles from '../styles.css'

class MessagesView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isMsgUpdate: false,
            title: '',
            avatar: null,
            messageCount: null,
            allMessageCount: null,
            isLoaded: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const topic = this.props.tinode.getTopic(this.props.selectedTopic);

        if(prevProps.selectedTopic !== this.props.selectedTopic) {
            if (prevProps.selectedTopic && !Tinode.isNewGroupTopicName(prevProps.selectedTopic)) {
                this.leave(prevProps.selectedTopic);
            }
            if (topic) {
                topic.onData = this.handleNewMessage;
                topic.onInfo = this.handleInfoReceipt;
                topic.onMetaDesc = this.handleDescChange;
                topic.onAllMessagesReceived = this.handleAllMessagesReceived;

                if (!topic.isSubscribed()) {
                    let getQuery = topic.startMetaQuery().withLaterDesc().withLaterSub();
                    getQuery = getQuery.withLaterData(MESSAGES_PAGE);
                    topic.subscribe(getQuery.build());
                }
            }
        }
    }

    handleInfoReceipt = (info) => {
        if(info.what === 'recv') {
            this.setState((prev) => ({
                isMsgUpdate: !prev.isMsgUpdate
            }))
        } else {
            console.info(`message info ${info.what}`)
        }
    }

    handleNewMessage = () => {
        this.setState((prevState) => ({
            isMsgUpdate: !prevState.isMsgUpdate
        }))
    }

    handleDescChange = (desc) => {
        if (desc.public) {
            this.setState({
                title: desc.public.fn,
                avatar: desc.public.photo,
                touched: desc.touched,
                isP2PTopic: Tinode.isP2PTopicName(desc.topic)
            });
        }
    }

    leave(oldTopicName) {
        if (!oldTopicName) {
            return;
        }
        let oldTopic = this.props.tinode.getTopic(oldTopicName);
        if (oldTopic && oldTopic.isSubscribed()) {
            oldTopic.leave(false)
                .catch(() => { /* do nothing here */ })
                .finally(() => {
                    oldTopic.onData = undefined;
                    oldTopic.onMetaDesc = undefined;
                    oldTopic.onAllMessagesReceived = undefined;
                    this.setState((prevState) => ({
                        isMsgUpdate: !prevState.isMsgUpdate,
                        messageCount: null,
                        allMessageCount: null,
                        isLoaded: false
                    }))
                });
        }
    }

    handleAllMessagesReceived = (count) => {
        this.setState((prevState) => ({
            messageCount: count,
            isMsgUpdate: !prevState.isMsgUpdate
        }))
    }

    onHandleScroll = (event) => {
        const topic = this.props.tinode.getTopic(this.props.selectedTopic);
        if((event.target.scrollHeight + event.target.scrollTop - event.target.clientHeight) <= 0) {
            if (topic && topic.isSubscribed() && topic.msgHasMoreMessages()) {
                topic.getMessagesPage(MESSAGES_PAGE)
                    .catch((err) => console.error(err, 'fetch error'))
            }
        }
    }

    render() {
        const {selectedTopic, tinode, status} = this.props;
        const {messages, title, avatar, touched, isMsgUpdate, isP2PTopic} = this.state;
        if(!selectedTopic) {
            return null;
        }

        return (
            <div className={styles.contentWrapper}>
                <Header>
                    <ContactInfo
                        title={title}
                        isAvatar={false}
                    >
                        <span className={styles.active}>{tinode.isTopicOnline(selectedTopic)
                            ? 'В сети'
                            : `Был активен: ${formatDate(touched)}`
                        }</span>
                    </ContactInfo>
                </Header>
                <Messages
                    isP2PTopic={isP2PTopic}
                    isMsgUpdate={isMsgUpdate}
                    messages={messages}
                    title={title}
                    selectedTopic={selectedTopic}
                    avatar={avatar}
                    tinode={tinode}
                    status={status}
                    onScroll={this.onHandleScroll}
                />
                {status === USERS_STATUS.deleted
                    ? <span>Пользователь удалён</span>
                    : <Submit
                        tinode={tinode}
                        selectedTopic={selectedTopic}
                    />}
            </div>
        )
    }
}

export default MessagesView

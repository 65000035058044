import React, {useMemo, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Message} from '../message';
import {formatDate} from '../../../../__data__/utils';

import style from './style.css';
import {createStructuredSelector} from 'reselect';
import {makeProfile} from '../../../../__data__/selectors/profile';
import {USERS_STATUS} from '../../../../constants/chat';

const mapStateToProps = createStructuredSelector({
    profileData: makeProfile(),
})

export const Messages = connect(mapStateToProps)(({title, avatar, selectedTopic, tinode, onScroll, isMsgUpdate, profileData, isP2PTopic, status}) => {
    const { userChatId } = profileData;
    const container = useRef();
    const topic = useMemo(() => tinode && tinode.getTopic(selectedTopic), [tinode, selectedTopic]);

    //загружаем новые сообщения
    useEffect(() => {
        const current = container.current;
        //container.current.scrollTo(0, -901);
        current.addEventListener('scroll', onScroll);
        return () => {
            current.removeEventListener('scroll', onScroll);
        }
    }, [onScroll])

    //когда подпишемся на топик, запросим сообщения обновлением флага isMsgUpdate
    const messages = useMemo(() => {
        if (!topic) {
            return [];
        }

        let updateMsg = [];

        topic.messages((msg) => {
            const content = msg.content;
            updateMsg.push({
                //иногда content - строка, а иногда - объект, приведем всегда к объекту
                content: typeof content === 'object' ? content : {txt: content},
                status: topic.msgStatus(msg, true),
                from: msg.from,
                ts: msg.ts,
                key: `${msg.topic}_${msg.seq}`
            })
        });
        //после загрузки сообщения отправляем событие 'read' и ловим его в resetContactList
        topic.noteRead();
        return updateMsg
    }, [topic, isMsgUpdate])

    return (
        <div ref={container} className={style.content}>
            <ul className={style.contentBlock} >
                {
                    messages.map((msg) => {
                        const {key, from, ts, ...rest} = msg;
                        return <Message
                            {...rest}
                            tinode={tinode}
                            key={key}
                            title={title}
                            isP2PTopic={isP2PTopic}
                            photo={avatar}
                            from={from}
                            ts={formatDate(ts)}
                            isReceivedMsg={userChatId !== from}
                        />
                    })
                }
                {(status === USERS_STATUS.suspended)
                && <li>Пользователь заблокирован, но ему можно написать</li>
                }
                {(status === USERS_STATUS.denied)
                && <li>У пользователя нет доступа к чату, но ему можно написать</li>
                }
            </ul>
        </div>
    )
})

Messages.propTypes = {
    tinode: PropTypes.object.isRequired,
    title: PropTypes.string,
    avatar: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.string}),
    selectedTopic: PropTypes.string.isRequired,
    onScroll: PropTypes.func.isRequired,
    isMsgUpdate: PropTypes.bool.isRequired,
    isP2PTopic: PropTypes.bool
}

import React, { Component } from 'react'
import classNames from 'classnames'
import enhanceWithClickOutside from 'react-click-outside'

import { URL_LIST } from '../../../../../__data__/constants'

import Action from './action'
import ActionWrapper from './action-wrapper'
import style from './style.css'
import avatarStub from "../../../../tasks/components/set-performer-form/avatar.png";

class Comp extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }

    handleClickAvatar = (e) => {
        e.preventDefault()
        this.setState({ isOpen: !this.state.isOpen })
    }

    handleClickAvatarStub = (e) => {
        e.preventDefault()
        this.setState({ isOpen: !this.state.isOpen })
    }

    /* На самом деле он используется в enhanceWithClickOutside. Вот такая неявность. */
    handleClickOutside() {
        this.setState({ isOpen: false })
    }

    render() {
        return (
            <div className={style.container}>
                {this.props.avatar ?
                    <div
                        className={style.imgContainer}
                        onClick={this.handleClickAvatar}
                    >
                        <img
                            className={style.img}
                            src={this.props.avatar || avatarStub}
                            alt="avatar"
                        />
                    </div> :
                    <a
                        className={style.imgStubContainer}
                        href={URL_LIST.avatarForm}
                    >
                        <svg className={style.imgStub} width="68" height="68" viewBox="0 0 68 68" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect width="68" height="68" fill="url(#paint0_linear)"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M39.6667 27.2C39.6667 30.3308 37.1308 32.8667 34 32.8667C30.8692 32.8667 28.3333 30.3308 28.3333 27.2C28.3333 24.0692 30.8692 21.5333 34 21.5333C37.1308 21.5333 39.6667 24.0692 39.6667 27.2ZM34 46.4667C40.2592 46.4667 45.3333 45.3166 45.3333 42.5C45.3333 39.6833 40.2592 36.2667 34 36.2667C27.7408 36.2667 22.6667 39.6833 22.6667 42.5C22.6667 45.3166 27.7408 46.4667 34 46.4667Z"
                                  fill="#9EAAB5"/>
                            <defs>
                                <linearGradient id="paint0_linear" x1="0.391597" y1="0.783195" x2="0.391597" y2="68"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F7F8FA"/>
                                    <stop offset="1" stop-color="#E3E4E5"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </a>
                }
                {this.state.isOpen && (
                    <div className={style.menuContainer}>
                        <div className={style.menuItemContainer}>
                            <a href={URL_LIST.avatarForm}
                               className={classNames(style.menuItem, style.menuItemLink)}>Загрузить другую</a>
                        </div>
                        <div className={style.menuItemContainer}>
                            <ActionWrapper>
                                <Action text='Удалить' processText='Удерживайте...'/>
                            </ActionWrapper>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default enhanceWithClickOutside(Comp)

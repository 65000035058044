import axios from 'axios'
import _ from 'lodash'

import {MODERATOR_STORAGE_NAME, PERMISSION_SECTIONS} from '../constants'
import * as types from '../actions-types'
import {getToken} from "../utils";
import LocalStorageUtil from "../../pages/chat-new/utils/local-storage";

export const fetchGetPermissions = () => ({type: types.GET_PERMISSIONS})
export const fetchGetPermissionsSuccess = (data) => ({type: types.GET_PERMISSIONS_SUCCESS, data})
export const fetchGetPermissionsFail = (error) => ({type: types.GET_PERMISSIONS_FAIL, error})

export const getPermissionSections = () => dispatch => {
    dispatch(fetchGetPermissions())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/permissions/`,
    })
        .then(response => {
            const permissionSections = _.get(response, ['data', 'sections'])
            LocalStorageUtil.setObject(PERMISSION_SECTIONS, {
                news: permissionSections.news,
                tasks: permissionSections.tasks,
                chats: permissionSections.chats,
                residents: permissionSections.residents,
                readings: permissionSections.readings,
                cameras: permissionSections.cameras,
                employees: permissionSections.employees,
                buildings: permissionSections.buildings,
            });
            dispatch(fetchGetPermissionsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetPermissionsFail(error))
        })
}

// Проверка доступа к разделам в боковом меню (хотя бы read-only).
export const checkPermissionForSideMenu = (section) => {
    const permissionSections = LocalStorageUtil.getObject(PERMISSION_SECTIONS)
    switch (section) {
        case 'tasks':
            section = 'tasks'
            break;
        case 'chats':
            section = 'chats'
            break;
        case 'residents':
            section = 'residents'
            break;
        case 'readings':
            section = 'readings'
            break;
        case 'cameras':
            section = 'cameras'
            break;
        default:
            // костыль для открытия доступа разделам, которые тут не указаны
            // (новости всегда доступны всем пользователям)
            section = 'news'
    }
    const isItDefaultSections = section === 'news'
    const isItAvailableByPerms = permissionSections && permissionSections[section] && ['read', 'write', 'performer'].includes(permissionSections[section])
    return isItAvailableByPerms || isItDefaultSections
}

// Проверка базового доступа к подразделам (хотя бы read-only).
export const checkPermissionForSection = (section) => {
    const permissionSections = LocalStorageUtil.getObject(PERMISSION_SECTIONS)

    switch (section) {
        case 'Сотрудники':
            section = 'employees'
            break;
        case 'Объекты':
            section = 'buildings'
            break;
        case 'Общение':
            section = 'chats'
            break;
        default:
            // костыль для открытия доступа разделам, которые тут не указаны
            // (новости всегда доступны всем пользователям)
            section = 'news'
    }
    const isItDefaultSections = section === 'news'
    const isItAvailableByPerms = permissionSections && permissionSections[section] && ['read', 'write'].includes(permissionSections[section])
    return isItAvailableByPerms || isItDefaultSections
}

// Проверка расширенного доступа к подразделам (write).
export const checkWritePermissionForSection = (section) => {
    const permissionSections = LocalStorageUtil.getObject(PERMISSION_SECTIONS)

    switch (section) {
        case 'Новости':
            section = 'news'
            break;
        case 'Заявки':
            section = 'tasks'
            break;
        case 'Резиденты':
            section = 'residents'
            break;
        case 'Показания':
            section = 'readings'
            break;
        case 'Камеры':
            section = 'cameras'
            break;
        case 'Сотрудники':
            section = 'employees'
            break;
        case 'Объекты':
            section = 'buildings'
            break;
        default:
            // костыль для закрытия доступа разделам, которые тут не указаны
            section = 'incorrectSection'
    }
    return permissionSections && permissionSections[section] && ['write', 'performer'].includes(permissionSections[section])
}
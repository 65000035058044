import React from 'react'
import _ from 'lodash'

import {formatPhoneNumber} from '../../../../../../__data__/utils'

import style from './style.css'

function Component(props) {
    const {phones, license_on, avatar, name_full, name_short} = props.companyInfo

    return (
        <div className={style.container}>
            <div className={style.personalInfo}>
                <div className={style.avatar}>
                    <img
                        className={style.img}
                        src={avatar}
                        alt="avatar"
                    />

                </div>
                <div className={style.mainComapanyDetailsInfo}>
                    <div className={style.mainComapanyDetailsInfoName}>{name_full}</div>
                    <div className={style.mainComapanyDetailsInfoOrganization}>{name_short}</div>
                </div>
            </div>
            <div className={style.details}>
                {_.map(phones, (phone) => (
                    <div className={style.detail}>
                        <div className={style.detailLabel}>{phone.group}</div>
                        <div className={style.detailValue}>{formatPhoneNumber(phone.number)}</div>
                    </div>
                ))}
                <div className={style.detail}>
                    <div className={style.detailLabel}>Дата регистрации</div>
                    {license_on && <div className={style.detailValue}>{license_on}</div>}
                </div>
            </div>
        </div>
    )
}

export default Component

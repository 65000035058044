import React, { useEffect, useCallback } from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import { createStructuredSelector } from 'reselect'
import classNames from 'classnames'

import { deleteNotification } from '../../../__data__/actions/notifications'

import {newsIcons, complaintIcons, bookletIcons, cameraIcons, taskIcons, chatIcons} from './constants'
import msgAudio from './msg.mp3'
import style from './style.css'

function Component(props) {
    const { message, reference, id, sentBy, subtype, type, isGrow, deleteNotification } = props

    useEffect(() => {
        const sound = new Audio(msgAudio)
        sound.play()
    }, [])

    const onCloseClick = useCallback(() => {
        deleteNotification({id})
    }, [id])

    const isNews = type === 'news'
    const isComplaint = type === 'complaint'
    const isBooklet = type === 'booklet'
    const isCamera = type === 'camera'
    const isTask = type === 'task'
    const isChat = type === 'chat'

    const action_click =
        isNews ? '/news' :
            isTask ? '/tasks' :
                isBooklet ? '/news' :
                    isCamera ? '/cameras' :
                        isComplaint ? '/chats?complaints=true' :
                            isChat ? `/chats${reference}` : '/news'

    return (
        <div className={classNames(style.wrapper, isGrow && style.grow)}>
            <div className={style.container}>
                <a href={action_click}>
                    <div className={style.content}>
                        {isNews && <div className={style.title}>{`${newsIcons[subtype]} ${sentBy} опубликовал(а)`}</div>}
                        {isTask && <div className={style.title}>{`${taskIcons[subtype]} ${sentBy} создал(а)/завершил(а) заявку`}</div>}
                        {isBooklet && <div className={style.title}>{`${bookletIcons[subtype]} ${sentBy} добавил(а) историю`}</div>}
                        {isCamera && <div className={style.title}>{`${cameraIcons[subtype]} ${sentBy} добавил(а) камеру`}</div>}
                        {isComplaint && <div className={style.title}>{`${complaintIcons[subtype]} ${sentBy} оставил(а) идею/благодарность/жалобу`}</div>}
                        {isChat && <div className={style.title}>{`${chatIcons[subtype]} ${sentBy} оставил(а) новое сообщение`}</div>}
                        <div className={style.descriptionWrapper}>
                            <div className={style.description}>{message}</div>
                        </div>
                    </div>
                </a>
                <div className={style.closeButton} onClick={onCloseClick}>
                    <svg className={style.closeIcon} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.25831 0.25831C0.602723 -0.0861033 1.16113 -0.0861032 1.50554 0.25831L7.74169 6.49446C8.0861 6.83887 8.0861 7.39728 7.74169 7.74169C7.39728 8.0861 6.83887 8.0861 6.49446 7.74169L0.25831 1.50554C-0.0861035 1.16113 -0.0861031 0.602723 0.25831 0.25831Z" fill="#8A9CAD"/>
                        <path d="M7.74169 0.25831C8.0861 0.602723 8.0861 1.16113 7.74169 1.50554L1.50554 7.74169C1.16113 8.0861 0.602723 8.0861 0.25831 7.74169C-0.0861033 7.39728 -0.0861031 6.83887 0.25831 6.49446L6.49446 0.25831C6.83887 -0.0861031 7.39728 -0.0861033 7.74169 0.25831Z" fill="#8A9CAD"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => createStructuredSelector({

})

const mapDispatchToProps = {
    deleteNotification,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)

// id: "news-27"
// message: "Пацаны, привет!"
// ref: "27"
// sentBy: "Александр Тимонин"
// subtype: "info"
// type: "news"

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const cells = [
    { id: 'flat', numeric: false, disablePadding: false, label: 'Помещение', width: 38 },
    { id: 'porch', numeric: false, disablePadding: false, label: 'Вход', width: 48 },
    { id: 'floor', numeric: true, disablePadding: false, label: 'Этаж', width: 52 },
    { id: 'pipe', numeric: true, disablePadding: false, label: 'Стояк', width: 56 },
    { id: 'residents', numeric: true, disablePadding: false, label: 'Резиденты'}
]

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    label: {
        fontSize: '12px',
        fontWeight: '600',
        color: '#757678',
    },
    th: {
        padding: '10px 0 4px 16px',
        borderColor: 'transparent',
        borderBottom: '1px solid #e3e4e5',
    }
}))

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props

    const classes = useStyles()
    const headCells = cells

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.th}
                        width={headCell.width}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <span className={classes.label}>{headCell.label}</span>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
}

export default EnhancedTableHead

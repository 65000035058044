import React from 'react'
import ReactTooltip from 'react-tooltip'

import { URL_LIST, PAGE_KEYS} from '../../../../__data__/constants'

import style from './style.css'
import { Item } from './components'

function Component(props) {
    const {pageKey} = props

    return (
        <div className={style.container}>
            <div className={style.firstMenuBlock}>
                <Item url={URL_LIST.news} dataTip='Новости' dataFor='tip-news' active={PAGE_KEYS.news == pageKey}>
                    <ReactTooltip place='right' id='tip-news' className={style.tooltip} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
                        <path fill="#94A0AC" fillRule="evenodd"
                              d="M5.501 16c.001-.203 4.001-.191 4 0-.005 1.1-1 2-2 2s-2.005-.898-2-2zm2-16C8.483 0 9.28 1.086 9.28 1.181c2.64.838 3.445 4.438 3.445 7.39l2.19 5.183c.215.508-.023 1.095-.532 1.31-.123.052-.255.079-.39.079H1.01c-.553 0-1-.448-1-1 0-.134.027-.266.079-.39l2.19-5.182c0-2.952.805-6.552 3.445-7.39C5.724 1.086 6.519 0 7.5 0z"/>
                    </svg>
                </Item>
                <Item url={URL_LIST.tasks} dataTip='Заявки' dataFor='tip-tasks' active={PAGE_KEYS.tasks == pageKey}>
                    <ReactTooltip place='right' id='tip-tasks' className={style.tooltip} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14">
                        <path fill="#94A0AC" fillRule="evenodd"
                              d="M10.87 0H8.132c-.503 0-.912.448-.912 1v12c0 .552.409 1 .912 1h2.736c.504 0 .912-.448.912-1V1c0-.552-.408-1-.912-1zm6.72 1h-2.737c-.503 0-.912.384-.912.857v10.286c0 .473.409.857.912.857h2.736c.504 0 .912-.384.912-.857V1.857c0-.473-.408-.857-.912-.857zM4.15 1H1.412c-.503 0-.912.384-.912.857v10.286c0 .473.409.857.912.857H4.15c.504 0 .912-.384.912-.857V1.857c0-.473-.408-.857-.912-.857z"/>
                    </svg>
                </Item>
                <Item url={URL_LIST.chats} dataTip='Общение' dataFor='tip-chats' active={PAGE_KEYS.chats == pageKey}>
                    <ReactTooltip place='right' id='tip-chats' className={style.tooltip} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
                        <path fill="#94A0AC" fillRule="evenodd"
                              d="M13.368.142l-11 .734C1.318.946.501 1.818.501 2.87V13l3.2-2.4c.52-.39 1.151-.6 1.8-.6h8c1.105 0 2-.895 2-2V2.138c0-1.105-.895-2-2-2-.044 0-.088.001-.133.004zM17.501 4v6c0 1.105-.895 2-2 2h-11v1c0 1.105.896 2 2 2h9c.65 0 1.281.21 1.8.6l3.2 2.4V6.442c0-.861-.55-1.626-1.367-1.898L17.5 4z"/>
                    </svg>
                </Item>
                <Item url={URL_LIST.residents} dataTip='Резиденты' dataFor='tip-residents'
                      active={PAGE_KEYS.residents == pageKey}>
                    <ReactTooltip place='right' id='tip-residents' className={style.tooltip} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16">
                        <path fill="#94A0AC" fillRule="evenodd"
                              d="M10.445 8.775c3.285 0 5.948 1.637 5.948 3.656l.001 2.952c-1.71.39-3.745.617-5.929.617-2.2 0-4.25-.23-5.968-.626v-2.943c0-2.019 2.663-3.656 5.948-3.656zM4.073 9.847c-.54.663-.869 1.58-.869 2.584v2.594C1.73 14.565.61 13.965 0 13.285l.001-.61c0-1.356 1.73-2.497 4.072-2.828zm12.854 0c2.342.331 4.072 1.472 4.072 2.827l.001.53c-.583.702-1.708 1.323-3.203 1.8V12.43c0-1.005-.33-1.921-.87-2.584zM4.539 2.437c.512 0 .988.147 1.4.41-.138 1.394.247 2.778 1.034 3.86-.458.937-1.373 1.58-2.434 1.58-1.517 0-2.746-1.31-2.746-2.925 0-1.615 1.23-2.925 2.746-2.925zm11.835 0c1.516 0 2.745 1.31 2.745 2.925 0 1.616-1.229 2.925-2.745 2.925-1.061 0-1.976-.643-2.434-1.58.787-1.082 1.171-2.466 1.034-3.86.412-.263.888-.41 1.4-.41zM10.493 0c1.794 0 3.25 1.455 3.25 3.25s-1.456 3.25-3.25 3.25c-1.795 0-3.25-1.455-3.25-3.25S8.698 0 10.493 0z"/>
                    </svg>
                </Item>
                <Item url={URL_LIST.meters} dataTip='Показания' dataFor='tip-readings'
                      active={PAGE_KEYS.meters == pageKey}>
                    <ReactTooltip place='right' id='tip-readings' className={style.tooltip} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
                        <path fill="#94A0AC" fillRule="evenodd"
                              d="M10.501 0c-5.523 0-10 4.477-10 10 0 2.652 1.054 5.196 2.93 7.071C5.305 18.946 7.848 20 10.5 20s5.196-1.054 7.071-2.929c1.876-1.875 2.93-4.419 2.93-7.071s-1.054-5.196-2.93-7.071C15.697 1.054 13.153 0 10.502 0m0 2c4.418 0 8 3.582 8 8 0 2.4-1 4.5-2.7 6-1.4-1.3-3.3-2-5.3-2s-3.8.7-5.3 2c-1.7-1.5-2.7-3.6-2.7-6 0-4.418 3.581-8 8-8m2.052 1.773L10.31 7.77l-.1.23c-.71.13-1.3.6-1.57 1.26-.41 1.03.09 2.19 1.12 2.6 1.03.41 2.19-.09 2.6-1.12.26-.66.14-1.42-.29-1.98l.1-.26 1.171-4.385c-.18-.307-.458-.387-.788-.342zM8.5 4c-.552 0-1 .448-1 1s.448 1 1 1c.553 0 1-.448 1-1s-.447-1-1-1m-3 3c-.552 0-1 .448-1 1s.448 1 1 1c.553 0 1-.448 1-1s-.447-1-1-1m10 0c-.552 0-1 .448-1 1s.448 1 1 1c.553 0 1-.448 1-1s-.447-1-1-1z"/>
                    </svg>
                </Item>
                <Item url={URL_LIST.cameras} dataTip='Камеры' dataFor='tip-readings'
                      active={PAGE_KEYS.cameras == pageKey}>
                    <ReactTooltip place='right' id='tip-cameras' className={style.tooltip} />
                    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#94A0AC" fillRule="evenodd" clipRule="evenodd" d="M11.6565 0.330698C11.2286 0.114889 10.8041 -3.8147e-05 9.63348 -3.8147e-05C9.63348 -3.8147e-05 1.77142 1.63076 1.34346 1.84657C0.9155 2.06238 0.579635 2.37907 0.35076 2.7826C0.121885 3.18612 0 3.58638 0 4.69017V7.82563C0 8.92941 0.121885 9.32967 0.35076 9.7332C0.579635 10.1367 0.9155 10.4534 1.34346 10.6692C1.77142 10.885 2.19591 11 3.36652 11H9.63348C10.8041 11 11.2286 10.885 11.6565 10.6692C12.0845 10.4534 12.4204 10.1367 12.6492 9.7332C12.8781 9.32967 13 8.92941 13 7.82563V3.1743C13 2.07051 12.8781 1.67025 12.6492 1.26672C12.4204 0.863197 12.0845 0.546506 11.6565 0.330698ZM15.6286 2.74852C15.249 2.90038 15 3.26809 15 3.67699V7.32293C15 7.73183 15.249 8.09954 15.6286 8.25141L18.6286 9.45141C19.2855 9.71415 20 9.23039 20 8.52293V2.47699C20 1.76953 19.2855 1.28577 18.6286 1.54852L15.6286 2.74852Z" />
                    </svg>
                </Item>
            </div>
            <div className={style.secondMenuBlock}>
                <Item url={URL_LIST.settings} dataTip='Настройки' dataFor='tip-settings'
                      active={PAGE_KEYS.settings == pageKey}>
                    <ReactTooltip place='right' id='tip-settings' className={style.tooltip} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <path fill="#94A0AC" fillRule="evenodd"
                              d="M10.002 13.5c-1.987 0-3.598-1.567-3.598-3.5s1.611-3.5 3.598-3.5C11.99 6.5 13.6 8.067 13.6 10s-1.61 3.5-3.598 3.5m7.639-2.53c.04-.32.072-.64.072-.97 0-.33-.031-.66-.072-1l2.169-1.63c.195-.15.247-.42.123-.64l-2.056-3.46c-.123-.22-.4-.31-.627-.22l-2.56 1c-.534-.39-1.09-.73-1.737-.98l-.38-2.65C12.53.18 12.314 0 12.057 0H7.946c-.257 0-.473.18-.514.42l-.38 2.65c-.648.25-1.203.59-1.738.98l-2.56-1c-.226-.09-.503 0-.627.22L.071 6.73c-.133.22-.072.49.124.64L2.364 9c-.041.34-.072.67-.072 1 0 .33.03.65.072.97l-2.17 1.66c-.195.15-.256.42-.123.64l2.056 3.46c.124.22.401.3.627.22l2.56-1.01c.535.4 1.09.74 1.738.99l.38 2.65c.041.24.257.42.514.42h4.112c.257 0 .473-.18.514-.42l.38-2.65c.648-.26 1.204-.59 1.738-.99l2.56 1.01c.226.08.504 0 .627-.22l2.056-3.46c.124-.22.072-.49-.123-.64l-2.17-1.66z"/>
                    </svg>
                </Item>
                <Item url={URL_LIST.profile} dataTip='Профиль' dataFor='tip-profile'
                      active={PAGE_KEYS.profile == pageKey}>
                    <ReactTooltip place='right' id='tip-profile' className={style.tooltip} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                        <path fill="#94A0AC" fillRule="evenodd"
                              d="M14.929 5.171c0-2.673-2.18-4.838-4.87-4.838S5.19 2.498 5.19 5.171s2.18 4.838 4.87 4.838 4.87-2.165 4.87-4.838zm-4.87 6.483c2.725 0 7.824 1.941 9.06 5.8-2.226 2.58-5.519 4.213-9.193 4.213-3.566 0-6.773-1.539-8.993-3.988 1.105-4.008 6.349-6.025 9.126-6.025z"/>
                    </svg>
                </Item>
            </div>
        </div>
    )
}

export default Component

import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import { getRepresentableTarget, formatDate } from '../../../../__data__/utils'

import style from './style.css'
import Images from './components/image-attachments'
import FileAttachments from './components/file-attachments'
import Menu from './components/context-menu'

const types = { info: "ИНФО", accident: "АВАРИЯ", report:"ОТЧЕТ", advert:"РЕКЛАМА", warn:"ВАЖНО", alert:"ВАЖНО", adv:"РЕКЛАМА" }

function Component(props) {
    let author = _.get(props.author, 'name')
    const avatar = _.get(props.author, 'avatar')
    if (author === 'me') {
        author = "Вы"
    }
    const target = getRepresentableTarget(props.target)
    const targetStr = author + ' → ' + target
    const date = formatDate(props.date_edit, 'D MMMM, в h:mm')

    return (
        <div className={style.container} id={props.id}>
            <div className={style.header}>
                <div className={style.title}>{props.title}</div>
                <div className={classNames(style.newsType, style[props.type])}>{types[props.type]}</div>
            </div>
            <div className={style.description}>{props.description}</div>
            <div className={classNames(!_.isEmpty((props.file_attachments) || !_.isEmpty(props.image_attachments)) && style.attachments)}>
                {!_.isEmpty(props.file_attachments) &&
                    <div className={style.files}>
                        <FileAttachments
                            fileNameList={props.file_attachments}
                        />
                    </div>
                }
                {!_.isEmpty(props.image_attachments) &&
                    <div className={style.images}>
                        <Images
                            imageNames={props.image_attachments}
                            carouselData={{
                                title: props.title,
                                target: targetStr,
                                date: date,
                                avatar: avatar,
                            }}
                        />
                    </div>
                }
            </div>
            <div className={style.footer}>
                <div className={style.footerLeft}>
                    {avatar && <img width="40px" height="40px" src={avatar} alt="Author's avatar"/>}
                    {!avatar &&
                        <svg width="40px" height="40px" viewBox="0 0 60 60" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                            <title>DISPLAY/avatar</title>
                            <defs>
                                <circle id="path-1" cx="30" cy="30" r="30"></circle>
                                <linearGradient x1="50%" y1="1.15175769%" x2="50%" y2="100%" id="linearGradient-3">
                                    <stop stopColor="#FFFFFF" offset="0%"></stop>
                                    <stop stopColor="#EAEAEA" offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <g id="SCREENS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="_ASSETS" transform="translate(-810.000000, -112.000000)">
                                    <g id="DISPLAY/avatar" transform="translate(810.000000, 112.000000)">
                                        <mask id="mask-2" fill="white">
                                            <use href="#path-1"></use>
                                        </mask>
                                        <use id="MASK" fill="#FFFFFF" fillRule="evenodd" href="#path-1"></use>
                                        <g id="XTRAZ/avatars/_person" mask="url(#mask-2)" fillRule="evenodd">
                                            <rect id="Rectangle" fill="url(#linearGradient-3)" x="0" y="0"
                                                  width="60" height="60"></rect>
                                            <path
                                                d="M37.5,21.25 C37.5,17.244375 34.255625,14 30.25,14 C26.244375,14 23,17.244375 23,21.25 C23,25.255625 26.244375,28.5 30.25,28.5 C34.255625,28.5 37.5,25.255625 37.5,21.25 Z M30.0498176,31 C34.0953955,31 41.6664583,33.9087309 43.5,39.6882178 C40.1959067,43.55356 35.3070398,46 29.8518504,46 C24.5572284,46 19.7960815,43.695457 16.5,40.0263081 C18.1397135,34.0220554 25.9261847,31 30.0498176,31 Z"
                                                id="Shape" fill="#B7BABF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    }
                    <div className={style.target}>{targetStr}</div>
                    <div className={style.date}>{date}</div>
                    <Menu newsId={props.id} checkedBuildings={props.checkedBuildings}/>
                    </div>
                </div>
            </div>
            )
            }

            export default Component

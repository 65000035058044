import axios from 'axios'
import _ from 'lodash'

import {CHAT_KEEP_LOGGED_IN, MODERATOR_STORAGE_NAME, MODERATOR_STORAGE_ROLE_NAME, URL_LIST} from '../constants'
import * as types from '../actions-types'

export const fetchLogin = () => ({ type: types.LOGIN })
export const fetchLoginSuccess = (data) => ({ type: types.LOGIN_SUCCESS, data })
export const fetchLoginFail = (error) => ({ type: types.LOGIN_FAIL, error })

export const fetchSendAccountConfirmationCode = () => ({ type: types.SEND_ACCOUNT_CONFIRMATION_CODE })
export const fetchSendAccountConfirmationCodeSuccess = (data) => ({ type: types.SEND_ACCOUNT_CONFIRMATION_CODE_SUCCESS, data })
export const fetchSendAccountConfirmationCodeFail = (error) => ({ type: types.SEND_ACCOUNT_CONFIRMATION_CODE_FAIL, error })

export const fetchSetAccountConfirmationCode = () => ({ type: types.SET_ACCOUNT_CONFIRMATION_CODE })
export const fetchSetAccountConfirmationCodeSuccess = (data) => ({ type: types.SET_ACCOUNT_CONFIRMATION_CODE_SUCCESS, data })
export const fetchSetAccountConfirmationCodeFail = (error) => ({ type: types.SET_ACCOUNT_CONFIRMATION_CODE_FAIL, error })

export const fetchSetPassword = () => ({ type: types.SET_PASSWORD })
export const fetchSetPasswordSuccess = (data) => ({ type: types.SET_PASSWORD_SUCCESS, data })
export const fetchSetPasswordFail = (error) => ({ type: types.SET_PASSWORD_FAIL, error })

export const fetchClearIsPasswordChanged = () => ({ type: types.CLEAR_IS_PASSWORD_CHANGED })

export const login = data => dispatch => {
    dispatch(fetchLogin())

    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data,
        url: '/api/auth',
    })
        .then(response => {
            // Доступные роли:
            // RESIDENT = 'resident', 'Резидент'
            // EMPLOYEE = 'employee', 'Работник'
            // COMPANY_ADMIN = 'admin', 'Администратор компании'
            // SUPERUSER = 'superuser', 'Суперпользователь
            const role = _.get(response, ['data', 'role'] )

            // С ролью resident нельзя пройти логин
            if (role === 'resident') {
                throw new Error('Уходи, резидент')
            }

            localStorage.setItem(MODERATOR_STORAGE_ROLE_NAME, role)

            const token = _.get(response, ['data', 'token'] )
            localStorage.setItem(MODERATOR_STORAGE_NAME, token)
            localStorage.setItem(CHAT_KEEP_LOGGED_IN, "true")

            // Редирект на страницу, с которой перешли на логин.
            const pastPage = _.replace(window.location.search, '?redirectPage=', '')
            const redirectPage = pastPage ? pastPage : URL_LIST.news
            dispatch(fetchLoginSuccess(redirectPage))
        })
        .catch(error => {
            dispatch(fetchLoginFail(error))
            throw new Error(error?.response?.data?.error)
        })
}

export const sendAccountConfirmationCode = data => dispatch => {
    dispatch(fetchSendAccountConfirmationCode())

    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data,
        url: `/api/anonymous/forgot_pass/send_confirm_code/`,
    })
        .then(response => {
            dispatch(fetchSendAccountConfirmationCodeSuccess(response))
        })
        .catch(error => {
            dispatch(fetchSendAccountConfirmationCodeFail(error))
        })
}

export const setAccountConfirmationCode = data => dispatch => {
    dispatch(fetchSetAccountConfirmationCode())
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data,
        url: `/api/anonymous/forgot_pass/confirm_reset_password/`,
    })
        .then(response => {
            dispatch(fetchSetAccountConfirmationCodeSuccess(response))
        })
        .catch(error => {
            dispatch(fetchSetAccountConfirmationCodeFail(error))
        })
}

export const setPassword = (data, token) => dispatch => {
    dispatch(fetchSetPassword())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        },
        data,
        url: `/api/crm/profile/set_password/`,
    })
        .then(response => {
            dispatch(fetchSetPasswordSuccess(response))
        })
        .catch(error => {
            dispatch(fetchSetPasswordFail(error))
        })
}

export const clearIsPasswordChanged = () => dispatch => {
    dispatch(fetchClearIsPasswordChanged())
}

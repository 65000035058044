import React, { useCallback } from 'react'

import style from './performer.css'
import avatarStub from './avatar.png'

const Component = props => {
    const { avatar, first_name, last_name, position, user, onChange } = props

    const handleChange = useCallback(e => {
        onChange(e.target.id)
    }, [onChange])

    return (
        <div className={style.container}>
            <input onChange={handleChange} className={style.radioButton} type="radio" id={user} name="contact" value={user} />
            <label className={style.label} htmlFor={user}>
                <img className={style.avatar} src={avatar || avatarStub} alt="avatar" />
                <div className={style.firstName}>{first_name}</div>
                <div className={style.lastName}>{last_name}</div>
                <div className={style.position}>{position}</div>
            </label>
        </div>
    )
}

export default Component

import React, { useState, useRef, useCallback } from 'react'
import _ from 'lodash'

import Loader from '../../../../../components/local-loader'
import { useOnClickOutside } from '../../../../../__data__/hooks'

import close from './X.svg'
import style from './style.scss'

function Component(props) {
    const { onSearch, data, isSearchEmpty, isError, isLoading, setValue, value } = props
    const widgetRef = useRef()
    const valuesRef = useRef()
    const inputRef = useRef()

    const [isModal, setModal] = useState(false)
    const [inputValue, setInputValue] = useState('')

    useOnClickOutside(widgetRef, () => setModal(false))

    const handleChange = useCallback(event => {
        setInputValue(event.currentTarget.value)
        onSearch(event)
        setModal(true)
    },[_.size(value)])

    const handleOptionClick = useCallback(event => {
        const activeItem = _.find(data, i => i.id === event.target.dataset.id)
        if (activeItem) {
            setValue([ ...value, activeItem ])
            setModal(false)
            setInputValue('')
            inputRef.current.focus()
        }
    },[_.size(value), data])

    // Когда в инпуте жмём <- или ->
    const handleInputKeyDown = event => {
        const key = event.keyCode || event.charCode
        // курсор ←
        if (key === 37) {
            const lastValue = _.last(_.get(valuesRef, 'current.children'))
            if (lastValue) {
                lastValue.focus()
            }
        }
        // курсор →
        if (key === 39) {

        }
    }

    const handleValueKeyDown = useCallback(event => {
        const key = event.keyCode || event.charCode
        // Backspace / delete
        if (key === 8 || key === 46) {
            const list = [ ...value ]
            _.remove(list, i => i.id === event.target.dataset.id)
            inputRef.current.focus()
            setValue(list)
        }
        // курсор ←
        if (key === 37) {
            const values = _.get(valuesRef, 'current.children')
            const currentIndex = _.findIndex(values, i => i.dataset.id === event.currentTarget.dataset.id)
            if (currentIndex > 0) {
                const prevElement = values[currentIndex - 1]
                prevElement.focus()
            }
        }
        // курсор →
        if (key === 39) {
            const values = _.get(valuesRef, 'current.children')
            const valuesSize = _.size(values)
            const currentIndex = _.findIndex(values, i => i.dataset.id === event.currentTarget.dataset.id)
            if (currentIndex < (valuesSize - 1)) {
                const nextElement = values[currentIndex + 1]
                nextElement.focus()
            } else {
                inputRef.current.focus()
            }
        }



    }, [_.size(value)])

    const handleClickDeleteValue = useCallback(event => {
        event.preventDefault()
        const list = [ ...value ]
        _.remove(list, i => i.id === event.currentTarget.dataset.id)
        setValue(list)
    }, [_.size(value)])

    return (
        <div className={style.container} ref={widgetRef}>
            <div className={style.inputContainer} >
                <ul className={style.values} role="listbox" ref={valuesRef}>
                    {_.map(value, i => (
                        <li onKeyDown={handleValueKeyDown} className={style.value} key={i.id} role='option' tabIndex={0} data-id={i.id}>
                            <span className={style.valueLabelPrev}>{i.labelPrev}</span>
                            <span className={style.valueLabel}>{i.label}</span>
                            <img className={style.closeIcon} onClick={handleClickDeleteValue} data-id={i.id} src={close}  alt='' />
                        </li>
                    ))}
                </ul>
                <input onKeyDown={handleInputKeyDown} ref={inputRef} onChange={handleChange} value={inputValue} className={style.input} placeholder={"Введите данные резидента (фамилия / имя / номер телефона / адрес)"} type='text' />
            </div>
            {(isModal && !isSearchEmpty) &&
                <div className={style.optionsContainer}>
                    {isLoading && <Loader />}
                    {isError && <div className={style.error}>Произошла непредвиденная ошибка</div>}
                    {!isLoading && !isError && (
                        <>
                            {_.isEmpty(data) && <div className={style.noMatchesAlert}>Ничего не нашлось. Измените параметры поиска</div>}
                            <ul className={style.options}>
                                {_.map(data, i => (
                                    <li onClick={handleOptionClick} className={style.option} key={i.id} data-id={i.id}>
                                        <span onClick={handleOptionClick} className={style.optionLabelPrev} data-id={i.id}>{i.labelPrev}</span>
                                        <span onClick={handleOptionClick} className={style.optionLabel}  data-id={i.id}>{i.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            }
        </div>
    )
}

export default Component

import React from 'react'
import CSVReader from 'react-csv-reader'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {createStructuredSelector} from 'reselect'
import {Field, getFormValues, isValid, reduxForm} from 'redux-form'
import _ from 'lodash'

import {FormHeader, FormWrapper} from '../../../../components/form/form-constructor/components'
import {FORM_NAMES, URL_LIST} from '../../../../__data__/constants'
import {convertResidents, importResidents} from '../../../../__data__/actions/residents'
import {makeImportedResidents} from '../../../../__data__/selectors/residents'
import {homeNumberRequired} from '../../../../components/form/validation'

import style from './style.css'
import HomeSelectInput from './home-select-input'
import Residents from './residents'
import onSubmitForm from "./submit";
import ReactTooltip from "react-tooltip";

export let Component = (props) => {
    const {convertResidents, formValues, convertedResidents, dispatch, handleSubmit, isFormValid} = props
    const homeNumber = _.get(formValues, 'homeNumber.value')
    const submitTipId = 'submitTipId'
    const csvExampleTipId = 'csvExampleTipId'
    const csvExampleTipData = 'unit - квартира, last_name - фамилия, first_name - имя, phone_number - номер телефона через +7 или 8'
    let isNothingToImport = _.isEmpty(convertedResidents.correctResidents)

    const onSubmit = () => {
        return onSubmitForm(convertedResidents.correctResidents, dispatch)
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)
        }
    }

    return (
        <FormWrapper>
            <FormHeader
                goBackLinkText='Вернуться к резидентам'
                goBackLinkUrl={URL_LIST.residents}
            />
            <form id={FORM_NAMES.downloadResidents} className={style.content} onSubmit={handleSubmit(onSubmit)}
                  onKeyPress={onKeyPress}>
                {(_.isEmpty(convertedResidents.correctResidents) && _.isEmpty(convertedResidents.incorrectResidents)) ?
                    <div className={style.fields}>
                        <Field
                            name='homeNumber'
                            component={HomeSelectInput}
                            label='Объект'
                            validate={[homeNumberRequired]}
                            defaultOpen={true}
                            formName={FORM_NAMES.downloadResidents}
                        />
                        {homeNumber &&
                            <div className={style.container}>
                                <div className={style.csvExample}>
                                    <h4>Пример CSV-файла с необходимыми заголовками</h4>
                                    <h4>&darr;</h4>
                                    <div className={style.csvExample} data-tip={csvExampleTipData} data-for={csvExampleTipId}>
                                        <ReactTooltip id={csvExampleTipId} className={style.tooltip} />
                                        <img src="/csvExample.jpg" alt="csvExample"/>
                                    </div>
                                </div>
                                <CSVReader cssClass={style.csvInput}
                                           label="Нажмите и выберите или перетащите CSV-файл в эту область"
                                           inputStyle={{ display: 'none' }}
                                           onFileLoaded={(data, fileInfo, originalFile) =>
                                               convertResidents({file: originalFile, building: homeNumber})}
                                />
                            </div>
                        }
                    </div> :
                    <Residents lists={convertedResidents}/>
                }
                <div className={style.footer} data-tip={'Приглашение будет отправлено только корректным резидентам'}
                     data-for={submitTipId}>
                    <ReactTooltip id={submitTipId} className={style.tooltip}/>
                    <button
                        disabled={isNothingToImport}
                        className={isFormValid && !isNothingToImport ? style.submit : style.hidden}
                        type="submit"
                        // fieldErrors={fieldErrors}
                    >Отправить приглашение
                    </button>
                </div>
            </form>
        </FormWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    formValues: getFormValues(FORM_NAMES.downloadResidents),
    convertedResidents: makeImportedResidents(),
    isFormValid: isValid(FORM_NAMES.downloadResidents),
})

const mapDispatchToProps = {
    convertResidents,
    importResidents
}

const withConnect = connect(
    mapStateToProps, mapDispatchToProps
)

Component = reduxForm({
    form: FORM_NAMES.downloadResidents,
})(Component)

export default compose(withConnect)(Component)

import React, {useEffect, useState} from 'react'
import _ from 'lodash'

import IncorrectList from './incorrect-list'
import Toggle from './toggle'
import style from './style.css'
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EnhancedTableHead from "./head";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '920px',
        marginTop: '20px',
        '& .MuiTableCell-root': {
            fontFamily: 'Open Sans',
        },
    },
    paper: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: '2px',
        border: 0,
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    tableContainer: {
        overflow: 'unset',
    },
    table: {
        borderColor: '#e3e4e5',
        color: '#2d313a',
        border: 0,
    },
    tbody: {
        border: '1px solid #e3e4e5',
        backgroundColor: '#fff',
        padding: '2px',
    },
    thWrapper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '28px',
    },
    mainTr: {
        backgroundColor: '#f7f8fa !important'
    },
    tr: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f7f8fa !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#f7f8fa !important',
            boxShadow: '0 2px 2px 2px rgba(255,255,255,0.50), inset 0 1px 4px 0 rgba(0,0,0,0.16)',
            '& .MuiTableCell-body': {
                color: '#000',
            }
        }
    },
    nameValue: {
        fontWeight: '600',
    },
    value: {
        fontSize: '13px',
        color: '#2d313a',
        padding: '6px 0 6px 16px',
    },
    center: {
        verticalAlign: 'middle'
    }
}))

export default function EnhancedTable(props) {
    const {lists} = props
    const {correctResidents, incorrectResidents} = lists

    const [activeTab, setTab] = useState('correct')
    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    useEffect(() => {
        document.body.style.overflow = "unset"
        document.body.style.height = "auto"
    }, [])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={style.headerWrapper}>
                    <div className={style.mainLogo}>
                        <img src="/logo192.png" alt="logo"/>
                        <h1>Проверьте корректность данных резидентов</h1>
                    </div>
                </div>
                <Toggle setTab={setTab} activeTab={activeTab}/>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        stickyHeader
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody className={classes.tbody}>
                            {activeTab === 'incorrect' && <IncorrectList list={incorrectResidents}/>}
                            {activeTab === 'correct' && _.map(correctResidents, (i, key) => (
                                <>
                                    <TableRow key={`${i.id}-${key}`}
                                              className={classes.mainTr}
                                              tabIndex={-1}>
                                        <TableCell>{i.label}</TableCell>
                                        <TableCell>{`${i.last_name} ${i.first_name}`}</TableCell>
                                        <TableCell>{i.phone_number}</TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <br/>
        </div>
    )
}
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field } from 'redux-form'
import _ from 'lodash'

import { default as MeterInput } from '.././meter-input'
import formStyle from '../../../theme/form.css'

import {
    makeBuildingSettingsMeterTypes,
    makeLastMonthMeters
} from "../../../__data__/selectors/building"

import style from './style.css'
import { formatDate } from "../../../__data__/utils";

function Component(props) {
    const { meterTypes, lastMonthMeters } = props
    const hasColdWater = _.includes(meterTypes, 'water_cold')
    const hasHotWater = _.includes(meterTypes, 'water_hot')
    const hasElecDay = _.includes(meterTypes, 'elec_day')
    const hasElecNight = _.includes(meterTypes, 'elec_night')
    const hasGas = _.includes(meterTypes, 'gas')
    const hasHeating = _.includes(meterTypes, 'heating')

    let lastColdWater = "000 000"
    let lastHotWater = "000 000"
    let lastElecDay = "000 000"
    let lastElecNight = "000 000"
    let lastGas = "000 000"
    let lastHeating = "000 000"

    let lastMetersSender = ''
    let lastMetersSenderType = ''
    let lastMonthMetersTooltip = ''

    if (lastMonthMeters) {
        lastColdWater = lastMonthMeters.meters.hasOwnProperty('water_cold') ? lastMonthMeters.meters['water_cold'] : "000 000"
        lastHotWater = lastMonthMeters.meters.hasOwnProperty('water_hot') ? lastMonthMeters.meters['water_hot'] : "000 000"
        lastElecDay = lastMonthMeters.meters.hasOwnProperty('elec_day') ? lastMonthMeters.meters['elec_day'] : "000 000"
        lastElecNight = lastMonthMeters.meters.hasOwnProperty('elec_night') ? lastMonthMeters.meters['elec_night'] : "000 000"
        lastGas = lastMonthMeters.meters.hasOwnProperty('gas') ? lastMonthMeters.meters['gas'] : "000 000"
        lastHeating = lastMonthMeters.meters.hasOwnProperty('heating') ? lastMonthMeters.meters['heating'] : "000 000"

        lastMetersSender = lastMonthMeters.sender
        if (lastMetersSender.type === 'employee') {
            lastMetersSenderType = 'сотрудник'
        } else if (lastMetersSender.type === 'resident') {
            lastMetersSenderType = 'резидент'
        } else {
            lastMetersSenderType = 'счётчик'
        }

        lastMonthMetersTooltip = `${formatDate(lastMetersSender.date)}, ${lastMetersSenderType} ${lastMetersSender.last_name} ${lastMetersSender.first_name}`
    }

    return (
        <Fragment>
            <div className={formStyle.groupSection}>
                {(hasColdWater || hasHotWater) &&
                <div className={style.inlineFields}>
                    {hasColdWater &&
                    <Field
                        name="data.water_cold"
                        component={MeterInput}
                        label="ХВС"
                        tooltip={lastMonthMetersTooltip}
                        size='lg1'
                        placeholder={lastColdWater}
                        icon='W'
                        iconColor='blue'
                        formName={props.formName}
                    />
                    }
                    {hasHotWater &&
                    <Field
                        name="data.water_hot"
                        component={MeterInput}
                        label="ГВС"
                        tooltip={lastMonthMetersTooltip}
                        size='lg1'
                        placeholder={lastHotWater}
                        icon='W'
                        iconColor='red'
                        formName={props.formName}
                    />
                    }
                </div>
                }
                {(hasElecDay || hasElecNight) &&
                <Fragment>
                    <div className={formStyle.delimiter} />
                    <div className={style.inlineFields}>
                        {hasElecDay &&
                        <Field
                            name="data.elec_day"
                            component={MeterInput}
                            label="Эл.день"
                            tooltip={lastMonthMetersTooltip}
                            size='lg1'
                            placeholder={lastElecDay}
                            icon='E'
                            iconColor='yellow'
                            formName={props.formName}
                        />
                        }
                        {hasElecNight &&
                        <Field
                            name="data.elec_night"
                            component={MeterInput}
                            label="Эл. ночь"
                            tooltip={lastMonthMetersTooltip}
                            size='lg1'
                            placeholder={lastElecNight}
                            icon='E'
                            iconColor='violet'
                            formName={props.formName}
                        />
                        }
                    </div>
                </Fragment>
                }
                {hasGas &&
                <Fragment>
                    <div className={formStyle.delimiter} />
                    <Field
                        name="data.gas"
                        component={MeterInput}
                        label="Газ"
                        tooltip={lastMonthMetersTooltip}
                        size='lg1'
                        placeholder={lastGas}
                        icon='G'
                        iconColor='blueLight'
                        formName={props.formName}
                    />
                </Fragment>
                }
                {hasHeating &&
                <Fragment>
                    <div className={formStyle.delimiter} />
                    <Field
                        name="data.heating"
                        component={MeterInput}
                        label="Отопление"
                        tooltip={lastMonthMetersTooltip}
                        size='lg1'
                        placeholder={lastHeating}
                        icon='G'
                        iconColor='red'
                        formName={props.formName}
                    />
                </Fragment>
                }
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    meterTypes: makeBuildingSettingsMeterTypes(ownProps.homeNumber),
    lastMonthMeters: makeLastMonthMeters(),
})

const withConnect = connect(
    mapStateToProps,
)

export default compose(withConnect)(Component)

import React, {PureComponent} from 'react';
import {IntlProvider} from 'react-intl';
import {createStructuredSelector} from 'reselect'
import {Tinode} from 'tinode-sdk';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom'
import {withRouter} from 'react-router';

import allMessages from './messages.json';

import {TABS, CHAT_TOKEN, USERS_STATUS, COMPLAINT_TYPE} from '../../constants/chat';
import LocalStorageUtil from './utils/local-storage';

import MessagesView from './view/messages-view';
import {ContactsView} from './view/contacts-view/contacts-view';
import {Tabs} from './components/tabs';
import Loader from '../../components/loader';
import styles from './styles.css';
import {getProfile, getUserProfile} from '../../__data__/actions/profile';
import {makeCheckedBuildings} from '../../__data__/selectors/common';
import {makeBuildingsList} from '../../__data__/selectors/buildings';
import {makeProfile, makeUserProfile} from '../../__data__/selectors/profile';
import {CHAT_KEEP_LOGGED_IN, MODERATOR_STORAGE_NAME, PROFILE_COMPANY_ID, URL_LIST} from '../../__data__/constants';
import {
    getBuildingAndPorchTagValues,
    getEmployeeTagValues,
    getResidentTagValues,
    TAGS_VALUES
} from './utils/tabs-strategy';
import {ComplaintsView} from "./components/complaint/complaints-view";
import {ComplaintsTypeList} from "./components/complaint/complaints-type-list";
import {wsBackAuth} from "../../components/custom-hooks/use-chat-login";
import msgAudio from "../../components/notifications/item/msg.mp3";
import {pushNotification} from "../../__data__/actions/notifications";
import queryString from "query-string";

const language = 'ru';
const messagesConf = allMessages[language]

class ChatsNew extends PureComponent {
    constructor(props) {
        super(props);
        this.tinode = props.tinode;
        // объект для соединения по websockets с бекендом приложения для получения обновлений по жалобам, объектам и тд
        this.wsBack = wsBackAuth()
        this.token = LocalStorageUtil.getObject(CHAT_KEEP_LOGGED_IN) ?
            LocalStorageUtil.getObject(CHAT_TOKEN) : undefined;
        const searchParams = new URLSearchParams(props.location.search);
        const complaints = searchParams.get('complaints');
        this.state = {
            //не уверена, что нужен
            autoLogin: false,
            connected: false,
            usersList: [],
            usersMap: new Map(),
            selectedTab: complaints == 'true' ? TABS[3] : TABS[0],
            selectedTopic: null,
            tabsMap: new Map(),
            isLoading: false,
            isTokenValid: true,
            isFoundContacts: false,
            foundUsersList: [],
            isSelectedTopicDeleted: false,
            isSelectedTopicUnavailable: false,
            selectedTopicStatus: null,
            previewComplaints: {},
            selectedComplaintType: null,
            complaintsList: null
        }
    }

    componentDidMount() {
        const {getProfile, checkedBuildings} = this.props;
        window.addEventListener('online', (e) => {
            this.handleOnline(true);
        });
        window.addEventListener('offline', (e) => {
            this.handleOnline(false);
        });
        getProfile();
        //пока всегда русский
        this.tinode.setHumanLanguage('ru');
        //при успешном коннеткте
        this.tinode.onConnect = this.handleConnected;
        this.tinode.onDisconnect = this.handleDisconnect;
        //если вылетаем, делаем реконнект (чтобы не падать с 503)
        this.tinode.onAutoreconnectIteration = this.handleAutoreconnectIteration;

        //read from cache
        this.resetContactList();
        // Обращения приходят с бэкенда, не от Tinode.
        this.waitForSocketConnection(this.wsBack.connection).then(() => {
            this.wsBack.connection.onmessage = (data) => {
                data = JSON.parse(data.data)
                const message_type = data.type
                switch (message_type) {
                    case "preview_complaints":
                        this.setState({
                            previewComplaints: data.data
                        })
                        break
                    case "get_complaints":
                        const complaints = data.data.results
                        this.setState({
                            complaintsList: complaints
                        })
                        this.handleComplaints(complaints)
                        break
                    case "receive_new_complaint":
                        break
                    case "new_complaint":
                        const newComplaint = data.data
                        if (this.state.previewComplaints) {
                            const possibleComplaint = COMPLAINT_TYPE.find(item => item.value === newComplaint.type);
                            if (possibleComplaint) {
                                this.setState(prevState => {
                                    const newPreviewComplaints = { ...prevState.previewComplaints };
                                    newPreviewComplaints[newComplaint.type]['last_complaint'] = newComplaint;
                                    return { previewComplaints: newPreviewComplaints };
                                });
                            }
                        }
                        if (this.state.complaintsList) {
                            let newComplaintList = [...this.state.complaintsList, ...[newComplaint]]
                            this.setState({
                                complaintsList: newComplaintList
                            })
                        }
                }
            }
        });

        if (!this.isTokenValid()) {
            this.removeInvalidToken();
        } else {
            this.setState(
                {autoLogin: true},
                () => {
                    this.token.expires = new Date(this.token.expires);
                    this.tinode.setAuthToken(this.token);
                    if (this.tinode.isConnected()) {
                        this.setState({
                            connected: true
                        })
                    } else {
                        this.tinode.connect().catch((err) => console.error(err, 'mount connect catch error'));
                    }
                    this.setState({
                        isTokenValid: true
                    })
                }
            );
        }
    }

    componentWillUnmount() {
        if (this.wsBack && this.wsBack.connection.readyState === WebSocket.OPEN) {
            this.wsBack.connection.close(1000, 'exit from the chat');
        }
    }

    isTokenValid = () => {
        if (!this.token) {
            return false;
        }
        return Number(new Date(this.token.expires)) > Number(new Date());
    }

    removeInvalidToken = () => {
        //все сбрасываем до начального состояния
        this.setState({
            isTokenValid: false,
            connected: false,
            usersList: [],
            selectedTab: TABS[0],
            tabsMap: new Map(),
            isFoundContacts: false,
            foundUsersList: [],
            isSelectedTopicDeleted: false,
            isSelectedTopicUnavailable: false
        }, () => {
            localStorage.removeItem(CHAT_TOKEN);
            localStorage.removeItem(MODERATOR_STORAGE_NAME);
        })
    }

    waitForSocketConnection(ws) {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (ws.readyState === 1) {
                    clearInterval(interval);
                    resolve();
                }
            }, 1000);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {connected, selectedTab} = this.state;
        //если на тиннод еще нет активной подписки, табы не подтянутся
        if (prevProps.checkedBuildings !== this.props.checkedBuildings || connected !== prevState.connected) {
            connected && this.chatTabsConverter();
            // Обращения приходят с бэкенда, не от Tinode.
            this.waitForSocketConnection(this.wsBack.connection).then(() => {
                this.wsBack.sendPreviewComplaintsRequest(this.props.checkedBuildings)
            });
        }
        if (prevState.selectedTab !== selectedTab) {
            this.resetContactList();
        }
    }

    handleDisconnect = (err) => {
        console.error(err || 'Disconnect');
        //все сбрасываем до начального состояния
        this.setState({
            connected: false,
            usersList: [],
            selectedTab: TABS[0],
            tabsMap: new Map(),
            isFoundContacts: false,
            foundUsersList: [],
            isSelectedTopicDeleted: false,
            isSelectedTopicUnavailable: false
        })
    }

    handleOnline = (isOnline) => {
        if (isOnline) {
            console.info('online')
            clearInterval(this.reconnectCountdown);
            this.tinode.reconnect();
            this.setState({
                connected: true
            });
        } else {
            console.info('offline')
            this.setState({
                connected: false
            })
        }
    }

    // Called for each auto-reconnect iteration.
    handleAutoreconnectIteration = (sec, prom) => {
        clearInterval(this.reconnectCountdown);

        if (sec < 0) {
            return;
        }

        if (prom) {
            // Reconnecting now
            prom.then(() => {
            }).catch((err) => {
                console.error(err.message, 'handleAutoreconnectIteration');
            });
            return;
        }

        let count = sec / 1000;
        count = count | count;
        this.reconnectCountdown = setInterval(() => {
            clearInterval(this.reconnectCountdown);
            this.tinode.reconnect();
            count -= 1;
        }, 1000);
    }

    chatTabsConverter() {
        const {checkedBuildings, buildingsList} = this.props;
        this.setState({
            isLoading: true
        })

        const sortedCheckedBuildingsIds = checkedBuildings.sort((a, b) => a - b);
        const profileCompanyId = LocalStorageUtil.getObject(PROFILE_COMPANY_ID)

        let allTabValue = [];
        const tabsMap = TABS.reduce((accum, current) => {
            if (current.value === TAGS_VALUES.all) {
                return accum;
            }
            if (current.value === TAGS_VALUES.resident) {
                const value = getResidentTagValues(sortedCheckedBuildingsIds, buildingsList);
                accum.set(current.value, value);
                allTabValue = allTabValue.concat(value);
            } else if (current.value === TAGS_VALUES.company) {
                const value = getEmployeeTagValues(profileCompanyId);
                accum.set(current.value, value);
                allTabValue = allTabValue.concat(value);
            }
            return accum;
        }, new Map());

        const buildings = getBuildingAndPorchTagValues(sortedCheckedBuildingsIds, buildingsList);
        allTabValue = allTabValue.concat(buildings);
        tabsMap.set(TAGS_VALUES.all, allTabValue);
        console.log("TABS MAP OF TAGS")
        console.log(tabsMap)
        this.setState({
            tabsMap
        }, () => {
            this.getAllContacts();
        })
    }

    handleConnected = () => {
        if (this.state.autoLogin) {
            this.authorization();
        }
    };

    authorization = () => {
        const cred = Tinode.credential();
        const token = this.tinode.getAuthToken();
        let promise = null;
        if (token) {
            promise = this.tinode.loginToken(token.token, cred);
        }
        if (promise) {
            promise.then((cred) => {
                if (cred.code === 200) {
                    this.handleLoginSuccessful();
                }
            }).catch((err) => {
                this.removeInvalidToken()
                console.error(err)
            })
        }
    };

    handleComplaints = (complaints) => {
        let unreadComplaints = []
        complaints.forEach(complaint => {
            if (complaint.unread)
                unreadComplaints.push(complaint.id)
        })
        if (unreadComplaints.length !== 0)
            this.wsBack.sendComplaintsReadRequest(unreadComplaints)
    }

    handleLoginSuccessful = () => {
        const me = this.tinode.getMeTopic();
        me.onContactUpdate = this.tnMeContactUpdate;
        //Called after a batch of subscription changes have been recieved and cached
        me.onSubsUpdated = this.tnSubsUpdated;
        this.setState({
            isLoading: true
        })

        // Subscribe, fetch topic desc, the list of subscriptions. Messages are not fetched.
        me.subscribe(
            me
                .startMetaQuery()
                .withLaterSub()
                .withDesc()
                .withTags()
                .withCred()
                .build()
        ).then(() => {
            const {location} = this.props;
            this.setState({
                connected: true,
                selectedTopic: new URLSearchParams(location.search).get('id')
            })
        }).catch((err) => {
            console.error(err);
            this.tinode.disconnect();
            this.removeInvalidToken();
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    tnMeContactUpdate = (what, cont) => {
        if (what == 'on' || what == 'off') {
            this.resetContactList();
            if (this.state.topicSelected == cont.topic) {
                this.setState({topicSelectedOnline: (what == 'on')});
            }
        } else if (what == 'read') {
            this.resetContactList();
        } else if (what == 'msg' && cont) {
            // [START] вызываем пуш в самом приложении (foreground)
            // отправляем пуши тогда и только тогда, когда мы находимся в другой вкладке чатов
            if (this.state.selectedTopic != cont.topic) {
                const id = `${cont._tinode._messageId}-${cont.seq}`
                // TODO: set a message as body for notification
                const message=''
                const ref = `?id=${cont.topic}`
                const sentBy = cont.public.fn
                const subtype = "message"
                const type = 'chat'
                const dataObject = {
                    message: message,
                    type: type,
                    subtype: subtype,
                    ref: ref,
                    sent_by: sentBy,
                    id: id
                };
                const notificationObject = {
                    data: dataObject
                };
                this.props.pushNotification(notificationObject)
            }
            // [END] вызываем пуш в самом приложении (foreground)

            // New message received. Maybe the message is from the current user, then unread is 0.
            if (cont.unread > 0) {
                // Skip update if the topic is currently open, otherwise the badge will annoyingly flash.
                if (document.hidden || this.state.topicSelected != cont.topic) {
                    const sound = new Audio(msgAudio)
                    sound.play().catch(_ => {
                        // play() throws if the user did not click the app first: https://goo.gl/xX8pDD.
                    });
                }
            }
            // Reorder contact list to use possibly updated 'touched'.
            this.resetContactList();
        } else if (what == 'recv') {
            // Explicitly ignoring "recv" -- it causes no visible updates to contact list.
        } else if (what == 'gone' || what == 'unsub') {
            // Topic deleted or user unsubscribed. Remove topic from view.
            // If the currently selected topic is gone, clear the selection.
            if (this.state.topicSelected == cont.topic) {
                this.handleTopicSelected(null);
            }
            // Redraw without the deleted topic.
            this.resetContactList();
        } else if (what == 'acs') {
            // Permissions changed. If it's for the currently selected topic,
            // update the views.
            if (this.state.topicSelected == cont.topic) {
                this.setState({topicSelectedAcs: cont.acs});
            }
        } else if (what == 'del') {
            // TODO: messages deleted (hard or soft) -- update pill counter.
        } else if (what == 'upd' || what == 'call') {
            // upd, call - handled by the SDK. Explicitly ignoring here.
        } else {
            // TODO(gene): handle other types of notifications:
            // * ua -- user agent changes (maybe display a pictogram for mobile/desktop).
            console.info("Unsupported (yet) presence update:", what, "in", (cont || {}).topic);
        }
    }

    resetContactList = () => {
        const {selectedTab, usersMap} = this.state;
        const {history} = this.props;

        if (usersMap.size === 0) {
            return;
        }
        const activeTab = usersMap.get(selectedTab.value);
        const queryParams = queryString.parse(window.location.search)
        if (selectedTab.value == TABS[3].value) {
            history.push({search: `?${new URLSearchParams({complaints: 'true'}).toString()}`});
        } else if (!queryParams) {
            history.push({search: ''})
        }

        this.tinode.getMeTopic().contacts((c) => {
            if (activeTab && activeTab.has(c.topic)) {
                Object.assign(activeTab.get(c.topic), {
                    ...c,
                    user: c.topic,
                    active: c.update
                })
            }
        });

        const past = new Date(0);
        const usersList = activeTab && Array.from(activeTab.values())
            .sort((a, b) => ((b.touched || past).getTime() - (a.touched || past).getTime()))
        this.setState({
            usersList,
            isLoading: false
        })
    }

    setTopicStatus = ({status}, isGroup) => {
        let selectedTopicStatus;
        if (isGroup) {
            this.setState({
                selectedTopicStatus: null
            })
            return;
        }
        if (status === USERS_STATUS.deleted || status === USERS_STATUS.suspended || status === USERS_STATUS.denied) {
            selectedTopicStatus = status
        }

        this.setState({
            selectedTopicStatus
        })
    }

    onContactClick = (user) => {
        const {history, getUserProfile} = this.props;
        const isGroupTopic = Tinode.isGroupTopicName(user.user || user.topic)
        history.push({search: `?${new URLSearchParams({id: user.user || user.topic}).toString()}`});
        getUserProfile(user.user || user.topic).then(() => {
            this.setTopicStatus(this.props.userProfile, isGroupTopic);
        });
        this.setState({
            selectedTopic: user.user || user.topic
        })
    }

    onComplaintTypeClick = (complaintType) => {
        const {checkedBuildings} = this.props;
        this.wsBack.sendComplaintsListRequest(complaintType, checkedBuildings)
        this.setState({
            selectedComplaintType: complaintType,
            complaintsList: null
        })
    }

    onTabClick = (selectedTab) => {
        this.setState({
            selectedTab,
            isFoundContacts: false
        })
    }

    onSearch = (query) => {
        const {usersList} = this.state;
        if (query.length === 0) {
            this.setState({
                isFoundContacts: false
            })
            return;
        }
        const foundUsersList = usersList.filter((c) => c.public.fn.indexOf(query) !== -1)
        this.setState({
            isFoundContacts: true,
            foundUsersList
        })
    }

    getAllContacts = async () => {
        const {tabsMap} = this.state;
        const tabs = tabsMap.get('all');
        let updatedList = [];

        tabs && await tabs.reduce((p, props) => (
                p.then(
                    () => new Promise((resolve, reject) => {
                        const fnd = this.tinode.getFndTopic();
                        let {tag, address, porch} = props;

                        if (tag.startsWith(TAGS_VALUES.company)) {
                            address = '[сотрудник компании]'
                        } else if (!tag.startsWith(TAGS_VALUES.resident) &&
                            (tag.includes(TAGS_VALUES.building) || tag.includes(TAGS_VALUES.porch))) {
                            address = undefined
                            porch = undefined
                        }

                        if (!fnd.isSubscribed()) {
                            fnd.subscribe(fnd.startMetaQuery().withSub().build())
                                .then(() => {
                                    this.tnFndSubsUpdated(fnd, tag, updatedList, resolve, reject, {address, porch});
                                })
                                .catch((err) => {
                                    reject();
                                    console.error(err);
                                })
                        } else {
                            this.tnFndSubsUpdated(fnd, tag, updatedList, resolve, reject, {address, porch});
                        }
                    })
                )
            ),
            Promise.resolve()
        )

        const usersMap = this.prepareChatContacts(updatedList);
        this.setState({
            usersMap,
            isTokenValid: true,
            isFoundContacts: false,
            isLoading: false
        }, () => {
            this.resetContactList();
        })
    }

    prepareChatContacts = (list) => {
        let usersMap = new Map();
        usersMap.set('all', new Map());
        usersMap = list.reduce((accum, current) => {
            let topic
            let keys = current.private || [];
            let tag = keys.join();
            // Для вкладки "Резиденты" отдаём резидентов с фильтром по объектам
            if (tag.includes(TAGS_VALUES.resident)) {
                if (tag.includes(TAGS_VALUES.building) || tag.includes(TAGS_VALUES.porch)) {
                    topic = current.user
                    if (accum.has(TAGS_VALUES.resident)) {
                        accum.get(TAGS_VALUES.resident).set(topic, current);
                    } else {
                        let contact = new Map();
                        accum.set(TAGS_VALUES.resident, contact.set(topic, current));
                    }
                    // Для вкладки "Все чаты" отдаём все результаты со всеми тегами
                    accum.get('all').set(topic, current);
                }
            // Для вкладки "Коллеги" отдаём всех коллег из компании твоего профиля
            } else if (tag.includes(TAGS_VALUES.company)) {
                topic = current.user
                if (accum.has(TAGS_VALUES.company)) {
                    accum.get(TAGS_VALUES.company).set(topic, current);
                } else {
                    let contact = new Map();
                    accum.set(TAGS_VALUES.company, contact.set(topic, current));
                }
                // Для вкладки "Все чаты" отдаём все результаты со всеми тегами
                accum.get('all').set(topic, current);
            } else if (tag.startsWith(TAGS_VALUES.building) || tag.startsWith(TAGS_VALUES.porch)) {
                topic = current.topic;
                // Для вкладки "Все чаты" отдаём все результаты со всеми тегами
                accum.get('all').set(topic, current);
            }
            // Ненужные пересечения по поиску - удаляем
            // (пример: по тегам "resident porch_1" & "porch_1" находятся дубли резидентов)
            if (topic === undefined) {
                accum.get('all').delete(topic)
            }
            return accum;
        }, usersMap);
        console.log("USERS MAP FOR TABS BY TAGS")
        console.log(usersMap)
        return usersMap;
    }

    tnFndSubsUpdated = (fnd, tag, usersList, resolve, reject, props) => {
        fnd.setMeta({desc: {public: tag}})
            .then((ctrl) => {
                return fnd.getMeta(fnd.startMetaQuery().withSub().build());
            }).then(() => {
            this.tinode.getFndTopic().contacts((s) => {
                usersList.push(Object.assign(s, props));
            });
            resolve();
        }).catch((err) => {
            reject();
            console.error(err.message, 'err subs update');
        })
    }

    render() {
        const {
            checkedBuildings
        } = this.props;
        const {
            usersList,
            selectedTopic,
            selectedTab,
            isLoading,
            isTokenValid,
            isFoundContacts,
            foundUsersList,
            selectedTopicStatus,
            previewComplaints,
            selectedComplaintType,
            complaintsList
        } = this.state;

        if (!isTokenValid) {
            return <Redirect to={URL_LIST.loginForm}/>
        }

        return (
            <IntlProvider
                locale={language}
                messages={messagesConf}
                textComponent={React.Fragment}
            >
                {isLoading && <Loader/>}
                <div className={styles.wrapper}>
                    <Tabs
                        previewComplaints={previewComplaints}
                        selectedTab={selectedTab}
                        onClick={this.onTabClick}
                    />
                    {selectedTab.value === 'complaint' ?
                        <div className={styles.inner}>
                                <ComplaintsTypeList
                                    previewComplaints={previewComplaints}
                                    selectedType={selectedComplaintType}
                                    onClick={this.onComplaintTypeClick}/>
                                <ComplaintsView
                                    complaintType={selectedComplaintType}
                                    complaintsList={complaintsList}
                                />
                        </div>
                        :
                        <div className={styles.inner}>
                            <ContactsView
                                selectedTab={selectedTab}
                                checkedBuildings={checkedBuildings}
                                tinode={this.tinode}
                                onClick={this.onContactClick}
                                usersList={isFoundContacts ? foundUsersList : usersList}
                                onSearch={this.onSearch}
                            />
                            <MessagesView
                                selectedTopic={selectedTopic}
                                tinode={this.tinode}
                                status={selectedTopicStatus}
                            />
                        </div>
                    }
                </div>
            </IntlProvider>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    buildingsList: makeBuildingsList(),
    profile: makeProfile(),
    userProfile: makeUserProfile()
})

const mapDispatchToProps = {
    getProfile,
    getUserProfile,
    pushNotification
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)

export default compose(withConnect)(withRouter(ChatsNew))

import React, { useState, useRef } from 'react'
import _ from 'lodash'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change } from 'redux-form'
import classNames from 'classnames'

import { useOnClickOutside } from '../../../../../../../__data__/hooks'

import Checkbox from './porche-field'
import style from './style.css'
import { getTargetText } from './utils'

function Component(props) {
    const {
        formValues,
        changeField,
        porches,
        porchesData,
        disabledPorches,
        buildingId,
        formName,
        input,
    } = props

    const ref = useRef()

    const [isModal, setModal] = useState(false)

    const setOptions = e => {
        e.preventDefault()
        setModal(!isModal)
    }

    useOnClickOutside(ref, () => setModal(false))

    const checkedFields = _.filter(_.get(formValues, input.name), i => i)
    const targetText = getTargetText(checkedFields, porches)

    const handleSetAll = e => {
        e.preventDefault()
        _.forEach(porches, (i, porchId) => {
            const name = `sortedPorches.${buildingId}.porches.${porchId}`
            changeField(formName, name, true)
        })
    }

    return (
        <div className={style.container}>
            <div
                className={classNames(style.value, isModal && style.valueActive)}
                onClick={setOptions}
            >
                <span className={style.valueText}>{targetText}</span>
                <svg className={classNames(style.valueIcon, !isModal && style.valueActiveIcon)} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.32562 0.281509C4.69807 -0.0938358 5.30193 -0.0938358 5.67438 0.281509L9.72066 4.35925C10.0931 4.73459 10.0931 5.34315 9.72066 5.71849C9.34821 6.09384 8.74435 6.09384 8.3719 5.71849L5 2.32038L1.6281 5.71849C1.25565 6.09384 0.651788 6.09384 0.279337 5.71849C-0.0931125 5.34315 -0.0931125 4.73459 0.279338 4.35925L4.32562 0.281509Z" fill="#1E1F21"/>
                </svg>
            </div>
            { /* 'Я не придумал как закрывать модалку при клике на велью, по этому перекрыл её заглушкой' */ }
            { isModal && <div className={style.valueOverlap} /> }
            {
                isModal && (
                    <div className={style.options} ref={ref}>
                        <div className={style.selectAllButtonWrapper}>
                            <div className={classNames(style.selectAllButtonContainer)}>
                                <a onClick={handleSetAll} className={style.selectAllButton} href="/">Выбрать все</a>
                            </div>
                        </div>
                        <div>
                            {_.map(porches, (i, porchId) => {
                                const name = `sortedPorches.${buildingId}.porches.${porchId}`
                                const label = _.get(_.find(porchesData, i => i.id.toString() === porchId), 'number')

                                return (
                                    <Field
                                        name={name}
                                        component={Checkbox}
                                        type="checkbox"
                                        label={label}
                                        id={name}
                                        validate={[]}
                                        key={porchId}
                                    />
                                )
                            })}
                            {_.map(disabledPorches, (i) => <div className={style.disabledPorches} key={i.id}>{`№ ${i.number}`}</div>)}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = () => createStructuredSelector({

})

const mapDispatchToProps = {
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)

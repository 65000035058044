import React, {useCallback} from 'react'
import _ from 'lodash'
import {makeStyles} from '@material-ui/core/styles'

import Paginator from '../../../../components/paginator'
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {default as EnhancedTableHead} from './head'
import {getUnits} from "../../../../__data__/actions/residents";
import style from "../../../../components/navigation/components/home-select-input/style.css";
import classNames from "classnames";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => {
        return [el, index]
    })
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    });
    return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '920px',
        marginTop: '20px',
        '& .MuiTableCell-root': {
            fontFamily: 'Open Sans',
        },
    },
    paper: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: '2px',
        border: 0,
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    tableContainer: {
        overflow: 'unset',
    },
    table: {
        borderColor: '#e3e4e5',
        color: '#2d313a',
        border: 0,
    },
    tbody: {
        border: '1px solid #e3e4e5',
        backgroundColor: '#fff',
        padding: '2px',
    },
    thWrapper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '28px',
    },
    mainTr: {
        backgroundColor: '#f7f8fa !important'
    },
    tr: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f7f8fa !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#f7f8fa !important',
            boxShadow: '0 2px 2px 2px rgba(255,255,255,0.50), inset 0 1px 4px 0 rgba(0,0,0,0.16)',
            '& .MuiTableCell-body': {
                color: '#000',
            }
        }
    },
    nameValue: {
        fontWeight: '600',
    },
    value: {
        fontSize: '13px',
        color: '#2d313a',
        padding: '6px 0 6px 16px',
    },
    center: {
        verticalAlign: 'middle'
    }
}))

const statusList = {
    added: {text: 'Добавлен', style: 'white'},
    blocked: {text: 'Заблокирован', style: 'red'},
    invited: {text: 'Приглашён', style: 'blue'},
    system: {text: 'В системе', style: 'blue'},
    active: {text: 'В системе', style: 'blue'},
    created: {text: 'Создан', style: 'blue'},
}

export default function EnhancedTable(props) {
    const {residentsObject, handleClick, selected} = props

    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const getDataPagination = (page, countPerPage) => {
        const rsData = getRsData()

        getUnits(rsData, page, countPerPage)
    }

    const isSelected = (userId) => userId === selected

    const getRsData = useCallback(() => {
        return {}
    }, [])

    if (!residentsObject || residentsObject.length < 1) {
        return null
    } else {
        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table
                            className={classes.table}
                            stickyHeader
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />

                            {/*<TableBody className={classes.tbody}>*/}
                            {/*    {stableSort(residentsObject.list, getComparator(order, orderBy))*/}
                            <TableBody className={classes.tbody}>
                                {_.map(residentsObject, group => {

                                    const buildingTableRow = (
                                            <TableRow key={_.get(group.building.id, 'object.building.id')}
                                                      className={classes.mainTr}
                                                      tabIndex={-1}>
                                                <TableCell colSpan={5}>
                                                    <div>
                                                        <svg className={classes.center} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.64762 1.33337C7.10585 1.33337 6.66667 1.77256 6.66667 2.31432V2.48276C6.66667 2.70593 6.77834 2.9143 6.96417 3.03788L7.11735 3.13974C7.49491 3.39082 8 3.12014 8 2.66671H14.6667V12.6667H12C11.6318 12.6667 11.3333 12.9652 11.3333 13.3334C11.3333 13.7016 11.6318 14 12 14H15C15.5523 14 16 13.5523 16 13V2.33337C16 1.78109 15.5523 1.33337 15 1.33337H7.64762ZM5.5547 3.70317C5.2188 3.47924 4.7812 3.47924 4.4453 3.70317L0.4453 6.36984C0.167102 6.55531 0 6.86754 0 7.20189V13C0 13.5523 0.447715 14 1 14H9C9.55228 14 10 13.5523 10 13V7.20189C10 6.86754 9.8329 6.55531 9.5547 6.36984L5.5547 3.70317ZM9.73695 4.00004C9.51404 4.00004 9.33333 4.18075 9.33333 4.40366C9.33333 4.53881 9.40098 4.665 9.51355 4.73981L10.3564 5.29999C10.3892 5.32176 10.4276 5.33337 10.467 5.33337C10.5773 5.33337 10.6667 5.24397 10.6667 5.13368V4.66671C10.6667 4.29852 10.3682 4.00004 10 4.00004H9.73695ZM12.6667 4.00004C12.2985 4.00004 12 4.29852 12 4.66671C12 5.0349 12.2985 5.33337 12.6667 5.33337C13.0349 5.33337 13.3333 5.0349 13.3333 4.66671C13.3333 4.29852 13.0349 4.00004 12.6667 4.00004ZM4.46312 5.34169C4.79069 5.13324 5.20931 5.13324 5.53688 5.34169L8.20354 7.03866C8.49199 7.22222 8.66667 7.54042 8.66667 7.88232V12.6667H6.66667V9.66671C6.66667 9.11442 6.21895 8.66671 5.66667 8.66671H4.33333C3.78105 8.66671 3.33333 9.11442 3.33333 9.66671V12.6667H1.33333V7.88232C1.33333 7.54042 1.50801 7.22222 1.79646 7.03866L4.46312 5.34169ZM12.6667 6.66671C12.2985 6.66671 12 6.96518 12 7.33337C12 7.70156 12.2985 8.00004 12.6667 8.00004C13.0349 8.00004 13.3333 7.70156 13.3333 7.33337C13.3333 6.96518 13.0349 6.66671 12.6667 6.66671ZM12.6667 9.33337C12.2985 9.33337 12 9.63185 12 10C12 10.3682 12.2985 10.6667 12.6667 10.6667C13.0349 10.6667 13.3333 10.3682 13.3333 10C13.3333 9.63185 13.0349 9.33337 12.6667 9.33337Z" fill="#2D313A"/>
                                                        </svg>
                                                        <span className={classNames(style.valueText, classes.center)} dangerouslySetInnerHTML={{__html: `${group.building.street} ${group.building.number}`}}/>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    return (
                                    <>
                                        <>
                                            {buildingTableRow}
                                            {_.map(group.units, unit => {
                                                const isItemSelected = isSelected(_.get(unit, 'object.id'))
                                                return (
                                                    <>
                                                    <TableRow key={_.get(unit, 'object.id')}
                                                              hover
                                                              className={classes.tr}
                                                              onClick={(event) => handleClick(event, unit.object.id, unit.residents)}
                                                              selected={isItemSelected}
                                                              aria-checked={isItemSelected}
                                                              tabIndex={-1}
                                                              role="checkbox">
                                                        <TableCell
                                                            dangerouslySetInnerHTML={{__html: `${_.get(unit, 'object.label')}`}}/>
                                                        <TableCell
                                                            dangerouslySetInnerHTML={{__html: _.get(unit, 'object.porch.number')}}/>
                                                        <TableCell
                                                            dangerouslySetInnerHTML={{__html: _.get(unit, 'object.floor.number')}}/>
                                                        <TableCell
                                                            dangerouslySetInnerHTML={{__html: _.get(unit, 'object.pipe.name')}}/>
                                                        <TableCell>
                                                            <span dangerouslySetInnerHTML={{__html: _.map(_.get(unit, 'residents'), (resident) => (`${resident.first_name} ${resident.last_name}`)).join(", ")}}/>
                                                        </TableCell>
                                                    </TableRow>
                                                    </>
                                                )
                                            })}
                                        </>
                                    </>
                                )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Paginator tableObject={residentsObject} getDataPagination={getDataPagination}/>
                </Paper>
            </div>
        )
    }
}
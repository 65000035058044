import { createSelector } from 'reselect'
import _ from 'lodash'

import { ACCESSES_TYPES } from '../constants'

const app = state => _.get(state, 'app', {})

export const makeProfile = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'profile')

        const isTokenError = _.get(slice, 'profile.isTokenError')
        if (isTokenError) return { isTokenError }

        const avatar = _.get(data, 'avatar')
        const firstName = _.get(data, 'first_name')
        const lastName = _.get(data, 'last_name')
        const phoneNumber = _.get(data, 'phone_number')
        const date = _.get(data, 'date')
        const subsidiary = _.get(data, 'subsidiary')
        const position = _.get(data, 'position')
        const sections = _.get(data, 'sections')
        const buildings = _.get(data, 'buildings')
        const porches = _.get(data, 'porches')
        const userChatId = _.get(data, 'user_chat_id')
        const company = _.get(data, 'company')

        const accesses = _.reduce(sections, (result, value, key) => {
            if (value !== 'denied') {
                if (!result) return ACCESSES_TYPES[key]
                return result + `, ${ACCESSES_TYPES[key]}`
            }
            return result
        }, '')

        const buildingList = _.map(buildings, i => ({
            id: i.id,
            building: `${_.get(i, 'street')} ${_.get(i, 'number')}`,
            complex: _.get(i, 'complex.name', 'Без комплекса'),
            porches: _.get(i, 'is_all_porches') ? 'Полностью' : `п. ${_.get(i, 'available_porches', 'None').map((porch) => porch.number).join(', ')}`,
        }))

        const porchesList = _.reduce(porches, (result, porch) => {
            let buildingId = _.get(porch, 'building.id')

            if (!_.find(result, (building, id) => +id === buildingId)) {
                result[buildingId] = {'porches': []}
            }

            result[buildingId]['building'] = `${_.get(porch, 'building.street')} ${_.get(porch, 'building.number')}`
            result[buildingId]['complex'] = _.get(porch, 'building.complex.name', 'Без комплекса')
            result[buildingId]['porches'].push(_.get(porch, 'number'))
            return result
        }, {})

        _.forEach(porchesList, i => {
            buildingList.push({
                building: i.building,
                complex: i.complex,
                porches: `Под. № ${i.porches.join(', ')}`,
            })
        })

        return {
            avatar,
            firstName,
            lastName,
            phoneNumber,
            date,
            subsidiary,
            position,
            accesses,
            userChatId,
            company,
            buildingList: _.sortBy(buildingList, i => i.complex),
        }
    })

export const makeUserProfile = () =>
    createSelector(app, slice => {
        const data = slice.userProfile || {}
        const position = data.position
        const status = data.status

        return {
            data,
            position,
            status,
        }
    })
